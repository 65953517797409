<template>
  <nav v-if="block.data" class="table-of-contents">
    <h2 v-if="block.properties.heading">{{ block.properties.heading }}</h2>
    <ul class="nav">
      <li v-for="section in block.data" :key="section.id" class="nav-item heading">
        <a href="#" v-scroll-to="{ el: section.id, offset: parseInt(block.properties.offset) }">{{ section.heading }}</a>
        <ul class="nav">
          <li v-for="subsection in section.sections" :key="subsection.id" class="nav-item subheading">
            <a href="#" v-scroll-to="{ el: subsection.id, offset: parseInt(block.properties.offset) }">{{ subsection.heading }}</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<style>
.table-of-contents .nav-item {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-of-contents .heading > a {
  display: block;
}
@media (max-width: 480px) {
  /* TODO This is only because it currently does not hide overflow properly on small screens */
  .table-of-contents .nav-item {
    white-space: normal;
  }
}
</style>

<script>
export default {
  name: 'BlockTableOfContents',
  props: {
    block: Object,
  },
  computed: {},
  data() {
    return {};
  },
};
</script>
